.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../images/luxury-perfume-glass-bottle-with-tuberose-flower-petals.jpg');
  background-size: cover;
  background-position: center;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.about-content {
  background: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background for readability */
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  max-width: auto;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

h1 {
  font-size: 3rem;
  margin: 0;
  color: #d5be12; 
  text-shadow: 0 4px 8px rgba(8, 8, 8, 0.5);
}

.perfume-image {
  width: 250px;
  height: auto;
  border-radius: 10px;
  margin-left: 20px;
  transition: transform 0.3s;
}

.perfume-image:hover {
  transform: scale(1.05);
}

.mission-statement, .story, .practices, .get-involved {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #fafafa;
}

.heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #d8c40e;
}

.company-name {
  font-weight: bold;
  color: #d8c40e;
}

.year {
  font-style: italic;
  color: #d8c40e;
}

a {
  color: #d8c40e;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #ffffff;
  text-decoration: none;
}
