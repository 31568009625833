.active{
  background-color: #746d0e;
}

.navbar.navbar-fixed-top {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d2ad31;
  color: white;
  position: relative;
  top: 0;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 7px;
}

.logo-container {
  margin-right: 20px; /* Adjust margin as needed */
}

.logo img {
  height: 50px;
  border-radius: 20px;
}

.title-container {
  display: flex;
  flex-direction: column;
}


.title {
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  text-decoration: none;
  color: #ffffff;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(1, 1, 1, 0.5);
}

.slogan {
  font-size: 1rem; /* Adjust font size as needed */
  color: #000000;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(1, 1, 1, 0.5);
  text-decoration: none;
  font-weight: bold;
}
nav ul {
  display: flex;
}

nav ul li {
 list-style: none; 
}

nav ul li a {
  display: block;
  text-decoration: none;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  color: #fffefe;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(1, 1, 1, 0.5);
  text-align: center;
}

nav ul li a:not(.active):hover {
  background-color:#000000 ;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.2rem;
}


@media (max-width: 992px) {
  

  nav .menu {
      display: flex;
  }

  nav {
      flex-direction: column;
      align-items: flex-start;
      overflow-x: hidden;
  }

  nav ul {
      display: none;
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.25rem;
  }

  nav ul.open {
      display: flex;
      background-image: url('/src/images/dark-nature-natural-perfume.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
  }

  nav ul li {
      width: 100%;
      text-align: center;
      font-weight: bolder;
  }

  nav ul li a {
      margin: 0.2rem 0.5rem;
  }
}