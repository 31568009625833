.home-container {
  position: relative;
  text-align: center;
  box-sizing: content-box;
  width: 100%;
  top: 0;
  overflow-x: hidden;
}

.body{

  overflow-x: hidden;

}

.home-image-container {
  position: relative;
  width: 100%;
  max-width: 2000px; /* Adjust the max-width as needed */
  height: 500px; /* Adjust the height as needed */
  margin: 0 auto;
  overflow: hidden;
}

.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.image-overlay {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

.image-text {
  font-size: 24px; /* Adjust the font size as needed */
  background-color: transparent;
  color: #fffdfd;
  text-shadow: 2px 2px 4px rgb(2, 39, 4); /* Added a subtle text shadow for readability */
}

.quote-button {
  position: absolute;
  font-weight: bold;
  bottom: 200px; /* Adjust the bottom position as needed */
  left: 5%;
  background-color: rgb(142, 136, 25);
  color: white;
  width: 150px;
  height: 50px;
  font-size: 20px; /* Adjust the font size as needed */
  transition: transform 0.3s ease;
}

.quote-button:hover {
  transform: translateY(-5px);
}

@media (max-width: 992px) {
  
  .home-container {
    position: relative;
    text-align: center;
    box-sizing: content-box;
    background-size: auto;  
    width: 100%;
}


  .home-image-container {
    height: 300px; /* Adjust the height for smaller screens */
    width: auto;
  }

  .home-content {
    font-size: 20px; /* Adjust font size for readability on small screens */
    bottom: 10px; /* Adjust positioning for smaller screens */
  }

  .image-text {
    font-size: 16px; /* Adjust the font size for readability */
  }

  .quote-button {
    bottom: 20px; /* Adjust position to be above other content */
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 16px;
  }
}
