/* Main container styling */
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-size: cover;
  margin-bottom: -30px;}

/* Contact container styling */
.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.contact-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.contact-content {
  font-size: 1.1em;
  line-height: 1.5;
  color: #555;
}

.contact-form {
  margin-top: 20px;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

.contact-input,
.contact-message {
  width: 95%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.contact-input:focus,
.contact-message:focus {
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
}

.contact-message {
  min-height: 100px;
  resize: vertical;
}

.placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1em;
  color: #999;
  pointer-events: none;
  transition: 0.2s;
}

.contact-input:focus + .placeholder,
.contact-input:not(:placeholder-shown) + .placeholder,
.contact-message:focus + .placeholder,
.contact-message:not(:placeholder-shown) + .placeholder {
  top: -20px;
  left: 10px;
  font-size: 0.85em;
  color: #333;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.85em;
  margin-top: 5px;
  text-align: left;
}

/* Button styling */
.contact-button {
  width: 100%;
  padding: 10px;
  background-color: #b8ad11;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #000000;
}

/* Contact details styling */
.contact-details {
  margin-top: 30px;
  background: linear-gradient(135deg, #1f1c2c, #e3cb53);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-family: 'Roboto', sans-serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-details:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.contact-details h2 {
  margin-bottom: 10px;
  font-size: 1.8em;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
}

.contact-details p {
  color: #eee;
  font-size: 1.1em;
  line-height: 1.6;
  white-space: pre-line;
}


/* Map container styling */
.map-container {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  color: #030303;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 10px;
}

/* Media queries for responsive design */
@media only screen and (max-width: 600px) {
  
  
  .contact-container {
    padding: 40px;
    margin-top: 60px;
  }

  .contact-title {
    font-size: 2.5em;
  }

  .contact-content {
    font-size: 1.2em;
  }

  .contact-button {
    font-size: 1.1em;
  }

  .contact-details h2 {
    font-size: 1.75em;
  }

  .contact-details p {
    font-size: 1.1em;
  }
}
